import React from 'react'
import styled from '@emotion/styled'
import { CommentCount } from 'gatsby-plugin-disqus'

const Wrapper = styled.div`
  margin: 0 auto 2em;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  color: ${props => props.theme.colors.grey_600};
`

const Date = styled.p`
  display: inline-block;
`

const ReadingTime = styled.p`
  display: inline-block;
`

const DotDivider = styled.span`
  margin: 0 0.5rem;
`

const CommentCountWrap = styled.div`
  display: inline-flex;
`

const AnchorLink = styled.a`
  transition: 0.2s;
  color: ${props => props.theme.colors.grey_600};

  &:hover {
    color: ${props => props.theme.colors.text};
  }
`

const PostDetails = props => {
  return (
    <Wrapper>
      <Date>{props.date}</Date>
      <DotDivider>&middot;</DotDivider>
      <ReadingTime>{`${props.timeToRead} min read `}</ReadingTime>
      {props.disqusConfig && (
        <CommentCountWrap>
          <DotDivider>&middot;</DotDivider>
          <AnchorLink href="#comments">
            <CommentCount config={props.disqusConfig} placeholder={'...'} />
          </AnchorLink>
        </CommentCountWrap>
      )}
    </Wrapper>
  )
}

export default PostDetails
