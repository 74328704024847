import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { useSiteMetadata } from '../hooks/use-site-metadata'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import Container from '../components/Container'
import PageBody from '../components/PageBody'
import TagList from '../components/TagList'
import PostLinks from '../components/PostLinks'
import PostDetails from '../components/PostDetails'
import SEO from '../components/SEO'
import { Disqus } from 'gatsby-plugin-disqus'

const PostTemplate = ({ data, pageContext }) => {
  const { siteUrl } = useSiteMetadata()
  const {
    title,
    id,
    metaDescription,
    heroImage,
    body,
    publishDate,
    tags,
  } = data.contentfulPost

  const previous = pageContext.prev
  const next = pageContext.next
  const { basePath } = pageContext
  let disqusConfig = {
    url: `${siteUrl}/${basePath}/${pageContext.slug}`,
    identifier: id,
    title: title,
  }

  let ogImage
  try {
    ogImage = heroImage.ogimg.src
  } catch (error) {
    ogImage = null
  }

  const Comments = styled.section`
    margin: ${props => props.theme.spacing[8]} auto 0;
    max-width: ${props => props.theme.sizes.maxWidthCentered};
    max-width: ${props => props.theme.sizes.maxWidthCentered};
    padding: ${props => props.theme.spacing[8]} 0 0;
    border-top: 1px solid ${props => props.theme.colors.secondary};

    @media screen and (min-width: ${props => props.theme.responsive.small}) {
      margin-top: ${props => props.theme.spacing[12]};
      margin-bottom: ${props => props.theme.spacing[4]};
    }

    h2 {
      margin: 0 0 ${props => props.theme.spacing[4]} 0;
      font-size: 1.875rem;
      line-height: 1.25;
      font-weight: 600;
      color: ${props => props.theme.colors.primary};
    }
  `

  return (
    <Layout>
      <SEO
        title={title}
        description={
          metaDescription
            ? metaDescription.internal.content
            : body.childMarkdownRemark.excerpt
        }
        image={ogImage}
      />
      <Hero title={title} image={heroImage} height={'50vh'} />
      <Container>
        {tags && <TagList tags={tags} basePath={basePath} />}
        <PostDetails
          date={publishDate}
          timeToRead={body.childMarkdownRemark.timeToRead}
          disqusConfig={disqusConfig}
        />
        <PageBody body={body} />
        <Comments>
          <h2 id="comments">Comments</h2>
          <Disqus config={disqusConfig} />
        </Comments>
      </Container>
      <PostLinks previous={previous} next={next} basePath={basePath} />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      title
      slug
      id
      metaDescription {
        internal {
          content
        }
      }
      publishDate(formatString: "MMMM DD, YYYY")
      publishDateISO: publishDate(formatString: "YYYY-MM-DD")
      tags {
        title
        id
        slug
      }
      heroImage {
        title
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        ogimg: resize(width: 1800) {
          src
        }
      }
      body {
        childMarkdownRemark {
          timeToRead
          html
          excerpt(pruneLength: 320)
        }
      }
    }
  }
`

export default PostTemplate
